const state = {
	pageTitle:'Турнирная таблица',
	menuTitleH1:'Чемпионат России 2022',
};
const getters = {
	pageTitle:state=>state.pageTitle,
	menuTitleH1:state=>state.menuTitleH1,
	menuTitleH2:(state,getters)=>getters.allData.stageName || "",
};
const actions = {
	setPageTitle(state,str){
		state.pageTitle=str;
	}
};

export default {
	state,
	getters,
	actions,
};


