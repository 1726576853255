export async function getData(){
	let url='https://volley.baikoff.ru/cms/api/v2/';

	const {gender,league,year} = getLSparams();
	let query=new URLSearchParams({gender,league,year}).toString();
	if(query){url+='?'+query}
	try{
		const response = await fetch(url);
		const {data,select} = await response.json();
		return {data,select}
	}catch(error){
		localStorage.removeItem('gender')
		localStorage.removeItem('league')
		localStorage.removeItem('year')
		localStorage.removeItem('stage')
		return new Error('Проблема при загрузке страницы');
	}
}


export function getLSparams(){
	return {
		gender:localStorage.getItem('gender')	|| 0,
		league:localStorage.getItem('league')	|| 0,
		year:localStorage.getItem('year')		|| 0,
		stageId:localStorage.getItem('stage')	|| 0,
	}
}