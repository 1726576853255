import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/Home.vue'),
		meta: { pageName: 'Турнирная таблица'}
	},
	{
		path: '/next',
		name: 'next',
		component: () => import('../views/Next.vue'),
		meta: { pageName: 'Расписание'}
	},
	{
		path: '/results',
		name: 'results',
		component: () => import('../views/Results.vue'),
		meta: { pageName: 'Результаты'}
	},
	{
		path: '/settings',
		name: 'settings',
		component: () => import('../views/Settings.vue'),
		meta: { pageName: 'Настройки'}
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
