<template lang='pug'>
header.header
	.header__menu
		mobile-menu
	.header__title {{pageTitle}}
main
	router-view
loading
</template>


<script>
import mobileMenu from '@/components/menu.vue'
import loading from '@/components/loading.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
	components: { mobileMenu,loading},
	setup(){
		const store = useStore()
		let pageTitle=computed(() => store.getters.menuTitleH2)
		return {
			pageTitle
		}
	}
}
</script>


<style lang="scss">
	body{
		background: #e4e4e7;
		color:#27272a;
	}
	.header{
		&{
			background: #164e63;
			height:48px;
			position: sticky;
			top:0;
			display: flex;
			align-items: center;
			padding: 0px 20px;
			gap:20px;
			z-index: 100;
		}

		&__title{
			color:#fff;
		}
	}
	main{
		padding: 20px;
	}
</style>