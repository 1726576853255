import {getLSparams} from '../api/vfv'

let {gender,league,year,stageId,teamId} = getLSparams();
const state = {
	gender,league,year,stageId,teamId,activeTeam:0
}

const getters = {
	// activeTeam:state=>state.activeTeam,
};

const mutation = {

};
const actions = {

};

export default {
	state,
	getters,
	actions,
	mutation,
};


