import {getData as apiData} from '../api/vfv'


const state = {
	_allData:[],
	_selectFields:{},

	_loading:false,
	loadData:false,

	_stage:parseInt(localStorage.getItem('stage')) || 0,
	
	activeTeam:0,
};

const getters = {
	loading:state=>state._loading,
	loadData:(state,getters)=>Object.keys(getters.allData).length>0,

	selectFields:state=>state._selectFields || {},
	listGengers:state=>state._selectFields.gender || {},
	listLeagues:state=>state._selectFields.league || {},
	listYears:state=>state._selectFields.year || {},

	allData:(state,getters)=>{
		let stage=getters.stage
		if(!(stage in state._allData)){return {}}
		return state._allData[stage]
	},
	stages:state=>state._allData.map(item=>{return  {id:item.stageId,name:item.stageName}} )||[] ,
	stage:state=>{
		if(!(state._stage in state._allData)){return 0}
		return state._stage || 0
	},

	resTable:(state,getters)=>getters.allData?getters.allData.teams:{},

	activeTeam:state=>state.activeTeam,
	
	teams:(state,getters)=>{
		const teams=getters.allData.teams? getters.allData.teams:{};
		if(getters.activeTeam && teams[getters.activeTeam]){
			teams[getters.activeTeam].active=true;
		}
		return teams
	},
	events:(state,getters)=>{
		const events=getters.allData.events?getters.allData.events.filter(element=>(getters.activeTeam==0 || element.team1 == getters.activeTeam || element.team2 == getters.activeTeam)) : [];
		const teams=getters.teams;
		if(events){
			events.forEach(element => {
				element.teams={
					1:{
						name:teams[element.team1].name,
						city:(teams[element.team1].name==teams[element.team1].city)?'':teams[element.team1].city,
						score:(element.score)?element.score.split(':')[0]:0
					},
					2:{
						name:teams[element.team2].name,
						city:(teams[element.team2].name==teams[element.team2].city)?'':teams[element.team2].city,
						score:(element.score)?element.score.split(':')[1]:0
					}
				}
			});
		}
		return events;
	},

	resEvents:(state, getters)=>getters.events.filter(ev=>ev.status==1),
	nextEvents:(state, getters)=>getters.events.filter(ev=>ev.status==0),
};

const mutations = {
	startLoading:(state)=>{
		state._loading=true;
	},
	finishLoading:(state)=>{
		state._loading=false;
	},
	setAllData: (state, payload) => {
		state._allData = payload;
	},
	setStage: (state, payload) => {
		state._stage = payload;
	},
	setSelectFields: (state,payload)=>{
		state._selectFields = payload;
	},
	setActiveTeam: (state,n)=>{
		state.activeTeam = n;
	},
};

const actions = {
	doApi: async({commit})=>{
		commit('startLoading');
		try {
			const {data,select}=await apiData();
			if(data){
				commit('setAllData', data);
			}
			if(select){
				commit('setSelectFields', select);
			}
			commit('finishLoading');
		} catch (error) {
			commit('finishLoading');
		}
		
	},

	getAllData: async ({dispatch,getters}) => {
		if(!getters.loadData){
			dispatch('doApi')
		}
	},

	getResTable:(context)=>actions.getAllData(context)
};

export default {
	state,
	getters,
	mutations,
	actions,
};

