import { createStore } from 'vuex'
import vfvdata from './vfvdata'
import page from './page'
import lsParams from './lsParams'

export default createStore({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {vfvdata,page,lsParams}
})
