<template lang='pug'>
transition(name="fade")
	.loading(v-if='loading')
		.loading__text Идет загрузка данных...
		.loading__icon
</template>

<script>
export default {
	name: 'loading',
	computed: {
		loading() {return this.$store.getters.loading},
	}
}
</script>

<style lang="scss">
.loading{
	&{
		display:flex;
		justify-content: space-between;
		position: fixed;
		left:0;
		right:0;
		bottom:0px;
		background-color: rgba(0,0,0,.4);
		color: #ddd;
		padding: 0 20px;
		align-items: center;
		height:40px;
		--color: #ddd;
		--size-square:15px;
	}

	&__text{
		color:var(--color);
		font-size: 13px;
	}

	&__icon{
		display: block;
		position: relative;

		width: 30px;
		height: 30px;
		display: grid;
		place-items: center;
	}

	&__icon::before,
	&__icon::after{
		content: '';
		box-sizing: border-box;
		position: absolute;
	}


	&__icon::before,
	&__icon::after {
		width: var(--size-square);
		height: var(--size-square);
		background-color: var(--color);
	}

	&__icon::before {
		top: calc(50% - var(--size-square));
		left: calc(50% - var(--size-square));
		animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
	}

	&__icon::after {
		top: 50%;
		left: 50%;
		animation: loader-7 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
	}

	@keyframes loader-6 {
		0%, 100% {transform: none;}
		25% {transform: translateX(100%);}
		50% {transform: translateX(100%) translateY(100%);}
		75% {transform: translateY(100%);}
	}

	@keyframes loader-7 {
		0%, 100% {transform: none;}
		25% {transform: translateX(-100%);}
		50% {transform: translateX(-100%) translateY(-100%);}
		75% {transform: translateY(-100%);}
	}
}
.fade-enter-active, .fade-leave-active {transition: opacity .5s ease;}
.fade-enter-from, .fade-leave-to{opacity:0;}
</style>